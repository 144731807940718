import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {AgendaMomentInterface} from "../../../common/interfaces/AgendaMomentInterface";
import {increaseAgendaMomentLimit, setActiveAgendaMoment, toggleSortFast} from "../appointmentSlice";
import {format} from 'date-fns';
import {nl} from 'date-fns/locale';
import {useEffect, useRef, useState} from "react";
import {css} from "@emotion/react";
import {BarLoader} from "react-spinners";
import {useLazyGetAgendaMomentsQuery} from "../../apiData/apiDataSlice";
import {deleteDossier, deleteDossierId, setDossierLoading} from "../../../app/rootReducer";
import {setGeneralStep} from "../../helpType/helpTypeSlice";
import {GeneralStepsEnum} from "../../../common/constants/GeneralStepsEnum";
import {dossierTypeEnum} from "../../../types/dossierTypeEnum";
import {fundingTypeEnum} from "../../../types/fundingTypeEnum";
import {useParams} from "react-router-dom";
import {damageLocations} from "../../../common/constants/damageOptions";

const override = css`
    display: block;
    margin: 6rem auto;
    border-color: red;
`;

const AppointmentEstablishments = () => {

    const {agendaMoments, userLocation} = useAppSelector((state) => state.appointment)
    const {dossier, getParams, dossierIsDeleted} = useAppSelector((state) => state.root);
    const {fundingType, postMissingContractor} = useAppSelector((state) => state.vehicleInformation);
    const {glassReplacement, activeHelpType} = useAppSelector((state) => state.helpType);
    const scrollRef = useRef<HTMLDivElement>(null);
    const isCaravanDamage = activeHelpType === dossierTypeEnum.CaravanRepair

    const [trigger, {data: response}] = useLazyGetAgendaMomentsQuery()
    const [isLoading, setIsLoading] = useState(false)

    const dispatch = useAppDispatch()
    let filteredEstablishments

    useEffect(() => {
        if (isLoading) {
            dispatch(setDossierLoading(true))
            return
        }

        dispatch(setDossierLoading(false))
    }, [isLoading]);

    const showMoreClick = () => {
        dispatch(setGeneralStep(GeneralStepsEnum.Appointment))
        dispatch(increaseAgendaMomentLimit())
    }

    useEffect(() => {
        if (dossier?.dossier_id && dossier?.dossier_access_token) {
            setIsLoading(true)
            trigger({
                dossierId: dossier?.dossier_id,
                dossierAccessToken: dossier?.dossier_access_token,
                lat: userLocation?.lat,
                long: userLocation?.lng,
                include_distances: true,
                sort: !!agendaMoments.fast ? 'date' : 'distance',
                fundingType: fundingType,
                preferredEstablishmentId: getParams.establishmentId,
                showOnlyPreferredEstablishment: getParams.forceEstablishment
            }).then(() => {
                setIsLoading(false)
            })
        }
    }, []);

    useEffect(() => {
            if (dossier?.dossier_id && dossier?.dossier_access_token) {
                setIsLoading(true)
                trigger({
                    dossierId: dossier?.dossier_id,
                    dossierAccessToken: dossier?.dossier_access_token,
                    lat: userLocation?.lat,
                    long: userLocation?.lng,
                    include_distances: true,
                    sort: !!agendaMoments.fast ? 'date' : 'distance',
                    fundingType: fundingType,
                    preferredEstablishmentId: getParams.establishmentId,
                    showOnlyPreferredEstablishment: getParams.forceEstablishment
                }).then(() => {
                    setIsLoading(false)
                })
            }
        }, [agendaMoments.fast]
    );

    useEffect(() => {
            if (dossier?.dossier_id && dossier?.dossier_access_token && userLocation.lat && userLocation.lng) {
                setIsLoading(true)
                trigger({
                    dossierId: dossier?.dossier_id,
                    dossierAccessToken: dossier?.dossier_access_token,
                    lat: userLocation?.lat,
                    long: userLocation?.lng,
                    include_distances: true,
                    sort: !!agendaMoments.fast ? 'date' : 'distance',
                    fundingType: fundingType,
                    preferredEstablishmentId: getParams.establishmentId,
                    showOnlyPreferredEstablishment: getParams.forceEstablishment
                }).then(() => {
                    setIsLoading(false)
                })
            }
        }, [userLocation.lat, userLocation.lng]
    );


    if (response) {
        const rearWindowValue = damageLocations.find(location => location.label === 'Achterruit')?.value;
        const roofWindowValue = damageLocations.find(location => location.label === 'Dakraam')?.value;
        const sideWindowValue = damageLocations.find(location => location.label === 'Zijruit')?.value;
        const glassDamageLocation = Number(dossier.glass_damage?.glass_damage_location);

        if (
            fundingType !== fundingTypeEnum.Self &&
            !isCaravanDamage &&
            glassDamageLocation !== rearWindowValue &&
            glassDamageLocation !== roofWindowValue &&
            glassDamageLocation !== sideWindowValue
        ) {
            filteredEstablishments = response.data
                .filter((agendaMoment: AgendaMomentInterface) => agendaMoment.availability?.dates?.find((availability) => availability.has_capacity))
                .slice(0, agendaMoments.limit);
        } else {
            filteredEstablishments = response.data.slice(0, agendaMoments.limit);
        }

        // Removing all unused establishments if one is already selected
        if (agendaMoments && agendaMoments.activeAgendaMoment && agendaMoments.activeAgendaMoment.establishment) {
            filteredEstablishments = response.data
                .filter((agendaMoment: AgendaMomentInterface) => {
                    if (agendaMoments.activeAgendaMoment) {
                        return agendaMoment.establishment.id === agendaMoments.activeAgendaMoment.establishment.id
                    }
                    return false
                })
                .slice(0, agendaMoments.limit)
        }
    }

    let {slug} = useParams();

    useEffect(() => {

        const localDossierUuidKey = slug + '-dossier_uuid';
        const localDossierAccessTokenKey = slug + '-dossier_access_token';

        if (fundingType === fundingTypeEnum.Self && agendaMoments.activeAgendaMoment && !dossierIsDeleted) {
            dispatch(deleteDossier({
                dossierUuid: localStorage.getItem(localDossierUuidKey) + '',
                fundingType: fundingTypeEnum.Self,
            })).then(() => {
                dispatch(deleteDossierId())
                localStorage.removeItem(localDossierUuidKey)
                localStorage.removeItem(localDossierAccessTokenKey)
            })
        }

    }, [fundingTypeEnum, agendaMoments.activeAgendaMoment])

    useEffect(() => {
        scrollRef.current?.scrollIntoView({block: "start", inline: "nearest", behavior: 'smooth'})
    }, [response])

    // If only one establishment is available, select it by default
    useEffect(() => {
        if (response && response.data && response.data.length && response.data.length === 1) {
            dispatch(setActiveAgendaMoment(response.data[0]))
        }
    }, [response])

    if (filteredEstablishments && dossier) {
        return (
            <>

                <div className="s-appointment__group border-color-primary" ref={scrollRef} id="establishment">

                    {!isLoading && filteredEstablishments && filteredEstablishments.length > 0 &&
                        <>
                            <h1 className="s-appointment__group__title">Selecteer een vestiging</h1>

                            {!!activeHelpType && (activeHelpType === dossierTypeEnum.BodyRepair || activeHelpType === dossierTypeEnum.CaravanRepair) && fundingType !== fundingTypeEnum.Self &&
                                <div className="c-form__row">
                                    <div className="c-form__group c-form__group--checkbox">
                                        <label>
                                            <input type="checkbox" value={agendaMoments.fast}
                                                   checked={!!agendaMoments.fast}
                                                   readOnly={true}
                                                   onClick={() => dispatch(toggleSortFast())}/>
                                            <p>Ik wil zo snel mogelijk een afspraak</p>
                                        </label>
                                    </div>
                                </div>
                            }
                        </>
                    }

                    {!isLoading && !agendaMoments.activeAgendaMoment && filteredEstablishments && filteredEstablishments.length === 0 &&
                        <div className="c-form__row">
                            <p>Helaas, er is geen beschikbare vestiging gevonden voor de ingevoerde gegevens.</p>
                        </div>
                    }

                    <BarLoader color={'#E67A01'} loading={isLoading} css={override}/>

                    {!isLoading && filteredEstablishments && filteredEstablishments.length > 0 &&
                        <>

                            <div className="c-locations u-fade">
                                {filteredEstablishments && filteredEstablishments.map((agendaMoment: AgendaMomentInterface, index: number) => {
                                    return <Establishment {...agendaMoment}
                                                          key={index}/>
                                })}
                            </div>

                            {!agendaMoments.activeAgendaMoment && (response && response.count && response.count > agendaMoments.limit) &&
                                <div className="c-form__buttons">
                                    <button onClick={() => showMoreClick()}
                                            className="c-form__buttons__single c-form__buttons__single___primary c-form__buttons__single___primary--full-width background-primary">
                                        <span
                                            className="font-face-primary font-color-white">Toon meer vestigingen</span>
                                    </button>
                                </div>
                            }

                        </>
                    }

                </div>

                {fundingType === fundingTypeEnum.Self && agendaMoments.activeAgendaMoment &&
                    <div className="s-appointment__alert">
                        <h1 className="s-appointment__group__title">Wanneer je zelf de schade wilt betalen kun je alleen
                            telefonisch een afspraak maken</h1>
                        <p className="s-appointment__alert__text">Neem contact op
                            met <b>{agendaMoments.activeAgendaMoment?.establishment?.name}</b></p>
                        <div className="c-form__buttons">
                            <a href={'tel:' + agendaMoments.activeAgendaMoment?.establishment?.telephone[0]}
                               className="c-form__buttons__single c-form__buttons__single___primary button-background-color-dark">
                                <i className="icomoon-phone icomoon" style={{color: '#1d2d58'}}/>
                                <span
                                    className="font-face-primary">{agendaMoments.activeAgendaMoment?.establishment?.telephone[0]}</span>
                            </a>
                        </div>
                    </div>
                }

                {!glassReplacement && agendaMoments.activeAgendaMoment?.establishment && agendaMoments.activeAgendaMoment?.establishment.name && postMissingContractor && !isCaravanDamage &&
                    <div className="s-appointment__alert">
                        <>

                            <h1 className="s-appointment__group__title">Voor deze reparatie kun je alleen telefonisch een
                                afspraak maken.</h1>

                            <p className="s-appointment__alert__text">Neem contact op
                                met <b>{agendaMoments.activeAgendaMoment?.establishment.name}</b></p>
                            <div className="c-form__buttons">
                                <a href={'tel:' + agendaMoments.activeAgendaMoment?.establishment.telephone[0]}
                                   className="c-form__buttons__single c-form__buttons__single___primary button-background-color-dark">
                                    <i className="icomoon-phone icomoon" style={{color: '#1d2d58'}}/>
                                    <span
                                        className="font-face-primary">{agendaMoments.activeAgendaMoment?.establishment.telephone[0]}</span>
                                </a>
                            </div>
                        </>
                    </div>
                }


                {glassReplacement && agendaMoments.activeAgendaMoment?.establishment && agendaMoments.activeAgendaMoment?.establishment.name &&
                    <div className="s-appointment__alert">
                        {agendaMoments.activeAgendaMoment?.establishment && agendaMoments.activeAgendaMoment?.establishment.name && agendaMoments.activeAgendaMoment?.establishment.telephone && agendaMoments.activeAgendaMoment?.establishment.telephone[0] ?
                            <>

                                <h1 className="s-appointment__group__title">Voor deze ruit kun je alleen telefonisch een
                                    afspraak maken.</h1>

                                <p className="s-appointment__alert__text">Neem contact op
                                    met <b>{agendaMoments.activeAgendaMoment?.establishment.name}</b></p>
                                <div className="c-form__buttons">
                                    <a href={'tel:' + agendaMoments.activeAgendaMoment?.establishment.telephone[0]}
                                       className="c-form__buttons__single c-form__buttons__single___primary button-background-color-dark">
                                        <i className="icomoon-phone icomoon" style={{color: '#1d2d58'}}/>
                                        <span
                                            className="font-face-primary">{agendaMoments.activeAgendaMoment?.establishment.telephone[0]}</span>
                                    </a>
                                </div>
                            </>
                            :
                            <>
                                <h1 className="s-appointment__group__title">Voor deze ruit kun je alleen telefonisch een
                                    afspraak maken.</h1>
                                <p className="s-appointment__alert__text">Neem contact op met <b>ABS autoherstel</b></p>
                                <div className="c-form__buttons">
                                    <a href={'tel:0900-6611111'}
                                       className="c-form__buttons__single c-form__buttons__single___primary button-background-color-dark">
                                        <i className="icomoon-phone icomoon" style={{color: '#1d2d58'}}/>
                                        <span className="font-face-primary">0900-6611111</span>
                                    </a>
                                </div>
                            </>
                        }
                    </div>
                }
            </>

        )
    }

    return null
}


const Establishment = (props: AgendaMomentInterface) => {

    const {agendaMoments} = useAppSelector((state) => state.appointment)
    const {glassReplacement} = useAppSelector((state) => state.helpType)

    const {forceEstablishment} = useAppSelector((state) => state.root.getParams)
    const {fundingType, postMissingContractor} = useAppSelector((state) => state.vehicleInformation);
    const dispatch = useAppDispatch()

    let availability = null
    const {dossier} = useAppSelector((state) => state.root);

    const rearWindowValue = damageLocations.find(location => location.label === 'Achterruit')?.value;
    const roofWindowValue = damageLocations.find(location => location.label === 'Dakraam')?.value;
    const sideWindowValue = damageLocations.find(location => location.label === 'Zijruit')?.value;
    const glassDamageLocation = Number(dossier.glass_damage?.glass_damage_location);

    if ((fundingType !== fundingTypeEnum.Self  && props.availability && props.availability?.dates) && dossier.appointment_type.code !== dossierTypeEnum.CaravanRepair) {
        availability = props.availability.dates.find((availability) => availability.has_capacity)
    }

    let className = 'c-locations__single u-fade'
    let showCross = false
    if (agendaMoments && agendaMoments.activeAgendaMoment && props.establishment.id === agendaMoments.activeAgendaMoment.establishment.id) {
        className += ' isActive'
        showCross = true
    }

    const handleClick = (props: AgendaMomentInterface) => {
        dispatch(setGeneralStep(GeneralStepsEnum.Appointment));
        dispatch(setActiveAgendaMoment(props));

        if (dossier.appointment_type.code === dossierTypeEnum.CaravanRepair &&
            agendaMoments?.activeAgendaMoment?.establishment?.id !== props.establishment.id) {
            dispatch(setGeneralStep(GeneralStepsEnum.Form));
        }
    };

    if (
        availability ||
        (
            fundingType === fundingTypeEnum.Self ||
            dossier.appointment_type.code === dossierTypeEnum.CaravanRepair ||
            glassDamageLocation === rearWindowValue ||
            glassDamageLocation === roofWindowValue ||
            glassDamageLocation === sideWindowValue
        )
    ) { return (
            <div className={className} onClick={() => handleClick(props)}>

                <div className={'c-locations__single__info border-color-primary border-color-primary-hover'}>
                    {showCross &&
                        <div className={'c-locations__single__info__cross'}>
                            <img src="/images/cross-black.svg" alt="cross"/>
                        </div>
                    }

                    <address>
                        <h5 className={'font-face-primary'}>{props.establishment?.name}</h5>
                        {!postMissingContractor && !glassReplacement && availability && availability?.date && <p>
                            Afspraak mogelijk
                            op <u>{format(new Date(availability.date), 'dd MMMM', {locale: nl})}</u>
                            {props.availability?.showExtendedWaitingTimes &&
                                <strong> - Bij deze vestiging is het drukker dan normaal</strong>
                            }
                        </p>}
                        <span>{props.establishment?.address?.street} {props.establishment?.address?.house_number}, {props.establishment?.address?.zip_code} {props.establishment?.address?.city}</span>
                    </address>

                    <div className={'c-locations__single__info__secondary'}>
                        {(!forceEstablishment && props.distance?.distance) &&
                            <div className="background-secondary c-locations__single__info__secondary__badge">
                                {props.distance?.distance?.text}
                            </div>
                        }
                    </div>

                </div>

            </div>
        )
    }

    return null

}

export default AppointmentEstablishments
