import DayPicker, {DateUtils} from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {setDate} from "../appointmentSlice";
import {format} from "date-fns";
import moment from "moment";
import {useEffect, useRef} from "react";
import {setGeneralStep} from "../../helpType/helpTypeSlice";
import {GeneralStepsEnum} from "../../../common/constants/GeneralStepsEnum";

const MONTHS = [
    'januari',
    'februari',
    'maart',
    'april',
    'mei',
    'juni',
    'juli',
    'augustus',
    'september',
    'oktober',
    'november',
    'december',
];

const WEEKDAYS_SHORT = ['Zo', 'Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za'];

const AppointmentDayPicker = () => {
    const {dossier} = useAppSelector((state) => state.root)
    const {agendaMoments, selectedDate} = useAppSelector((state) => state.appointment)
    const {activeAgendaMoment} = agendaMoments
    const dispatch = useAppDispatch()
    const scrollRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        scrollRef.current?.scrollIntoView({block: "start", inline: "nearest", behavior: 'smooth'})
    }, [activeAgendaMoment])

    const handleDayClick = (day: Date) => {
        dispatch(setGeneralStep(GeneralStepsEnum.Appointment))
        dispatch(setDate(format(day, 'yyyy-MM-dd')))
    }

    if (activeAgendaMoment && activeAgendaMoment.availability && activeAgendaMoment.availability?.dates && dossier) {

        if (agendaMoments) {

            const disabledDays = activeAgendaMoment.availability.dates.filter((availability: any) => availability.has_capacity).map((availabilityItem: any) => {
                return new Date(availabilityItem.date)
            })

            const isDayDisabled = (day: Date) => {
                return !disabledDays.some((disabledDay: any) =>
                    DateUtils.isSameDay(day, disabledDay)
                )
            }

            return (
                <div className="s-appointment__group border-color-primary no-padding" ref={scrollRef}>
                    <h1 className="s-appointment__group__title">Selecteer datum</h1>
                    <div className="s-appointment__datepicker"
                         style={{
                             backgroundColor: 'white',
                             width: '100%',
                             height: '25rem',
                             borderRadius: '.5rem',
                             display: 'flex',
                             alignItems: 'center',
                             justifyContent: 'center'
                         }}
                    >

                        {selectedDate ?
                            <DayPicker
                                selectedDays={new Date(selectedDate)}
                                month={moment(activeAgendaMoment.availability.firstOption.day.date).toDate()}
                                disabledDays={isDayDisabled}
                                locale="nl"
                                onDayClick={handleDayClick}
                                months={MONTHS}
                                firstDayOfWeek={1}
                                navbarElement={<DayPickerNavbar/>}
                                weekdaysShort={WEEKDAYS_SHORT}
                            />
                            :
                            <DayPicker
                                month={moment(activeAgendaMoment.availability.firstOption.day.date).toDate()}
                                disabledDays={isDayDisabled}
                                locale="nl"
                                onDayClick={handleDayClick}
                                months={MONTHS}
                                firstDayOfWeek={1}
                                navbarElement={<DayPickerNavbar/>}
                                weekdaysShort={WEEKDAYS_SHORT}
                            />
                        }
                    </div>
                </div>
            )

        } else return null
    } else return null
}


const DayPickerNavbar = ({onPreviousClick, onNextClick, className, month}: any) => {

    const {agendaMoments} = useAppSelector((state) => state.appointment)
    const {activeAgendaMoment} = agendaMoments

    const handleNextClick = () => {
        onNextClick()
    };

    const handlePrevClick = () => {
        onPreviousClick()
    }

    return (
        <div className={className}>
            {moment(month).isAfter(moment(activeAgendaMoment.availability.firstOption.day.date).toDate()) &&
                <button className="button-prev" onClick={() => handlePrevClick()}>
                    <i className={'icon-circle-button-left'}/>
                </button>
            }
            <button className="button-next" onClick={() => handleNextClick()}>
                <i className={'icon-circle-button-right'}/>
            </button>
        </div>
    );
}


export default AppointmentDayPicker
