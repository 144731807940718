import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import FormInput from "../../../common/components/form/FormInput";
import {setExplanationCaravanDamage} from "../vehicleInformationSlice";
import {setGeneralStep} from "../../helpType/helpTypeSlice";
import {GeneralStepsEnum} from "../../../common/constants/GeneralStepsEnum";
import {useState, useEffect} from "react";

const VehicleInformationCaravanDamage = () => {
    const { caravanDamage } = useAppSelector((state) => state.vehicleInformation);
    const dispatch = useAppDispatch();
    const [isTextFieldEmpty, setIsTextFieldEmpty] = useState(true);

    useEffect(() => {
        setIsTextFieldEmpty(!caravanDamage.explanation);
    }, [caravanDamage.explanation]);

    useEffect(() => {
        window.dataLayer.push({
            event: 'CamperschadeAanmeldenUwschade'
        });
    }, []);

    return (
        <div className={"s-appointment__group border-color-primary"}>
            <h1 className="s-appointment__group__title">Toelichting soort schade</h1>
            <div className="c-form__row">
                <FormInput
                    type="text"
                    value={caravanDamage.explanation || ""}
                    placeholder={"Bijvoorbeeld plaats van schade op het voertuig…"}
                    onChange={(value) => {
                        dispatch(setExplanationCaravanDamage(value.toString()));
                        setIsTextFieldEmpty(!value);
                    }}
                />
            </div>
            <div className="c-form__buttons">
                <button
                    className="c-form__buttons__single c-form__buttons__single--full-width border-color-primary border-color-primary-hover"
                    onClick={() => {dispatch(setGeneralStep(GeneralStepsEnum.Appointment))}}
                    disabled={isTextFieldEmpty}
                >
                    <span className="font-face-primary">Ga verder</span>
                </button>
            </div>
        </div>
    );
};

export default VehicleInformationCaravanDamage;