import FormInput from "../../common/components/form/FormInput";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {
    setFirstName,
    setInsertion,
    setLastName,
    setAddressStreet,
    setAddressZipcode,
    setAddressHouseNumber,
    setAddressCity,
    setEmail,
    getAddressByZipCode, setDateErrorText, setDateError, setMobile, setAddressHouseNumberAddition
} from "./FormSlice";
import {useEffect, useRef, useState} from "react";
import {setDamageDate, setGreenCardCode} from "../vehicleInformation/vehicleInformationSlice";
import moment from "moment";
import {enableMobileNumberCheckModal, updateDossier} from "../../app/rootReducer";
import {PatchStepsEnum} from "../../common/constants/PatchStepsEnum";
import {setGeneralStep} from "../helpType/helpTypeSlice";
import {GeneralStepsEnum} from "../../common/constants/GeneralStepsEnum";
import FormInputPhoneNumber from "../../common/components/form/FormInputPhoneNumber";
import {dossierTypeEnum} from "../../types/dossierTypeEnum";

const Form = () => {


    const {dossier} = useAppSelector((state) => state.root)
    const {greenCardCode} = useAppSelector((state) => state.vehicleInformation)
    const {damageDate} = useAppSelector((state) => state.vehicleInformation);
    const {person, zipcodeLoading} = useAppSelector((state) => state.form)

    const [validZipcode, setValidZipcode] = useState(true)

    const [errorForm, setErrorForm] = useState(false)

    const isInitialMount = useRef(true);
    const scrollRef = useRef<HTMLFormElement>(null);

    const {helpType} = useAppSelector(state => state);
    const {activeHelpType} = helpType;

    const dispatch = useAppDispatch()

    useEffect(() => {
        const dutchZipcodeRegex = /^[1-9][0-9]{3}\s?[a-z]{2}$/i;
        const belgiumZipcodeRegex = /^[1-9][0-9]{3}$/;

        if ((person.address.zip_code.match(dutchZipcodeRegex) || person.address.zip_code.match(belgiumZipcodeRegex) || person.address.zip_code.length === 0)) {
            setValidZipcode(true)
        } else {
            setValidZipcode(false)
        }
    }, [person.address.zip_code]);

    useEffect(() => {
        if (!isInitialMount.current) {
            const delayDebounceFn = setTimeout(() => {
                dispatch(
                    updateDossier({
                        data: {damage_date: damageDate},
                        method: PatchStepsEnum.PatchDamageDate,
                    })
                )
            }, 800)
            return () => clearTimeout(delayDebounceFn)
        } else {
            isInitialMount.current = false;
        }
    }, [damageDate]);

    useEffect(() => {
            scrollRef.current?.scrollIntoView({block: "start", inline: "nearest", behavior: 'smooth'})

            if (activeHelpType === dossierTypeEnum.GlassReplacement || activeHelpType === dossierTypeEnum.GlassRepair) {
                window.dataLayer.push({
                    event: "RuitschadeAanmeldenAanvullend",
                })
            }

            if (activeHelpType === dossierTypeEnum.BodyRepair) {
                window.dataLayer.push({
                    event: "AutoschadeAanmeldenAanvullend",
                });
            }

            if (activeHelpType === dossierTypeEnum.CaravanRepair) {
                window.dataLayer.push({
                    event: 'CamperschadeAanmeldenAanvullend'
                });
            }


        }, []
    );

    useEffect(() => {
            const timeout = setTimeout(() => {
                if (person.address.house_number && person.address.zip_code) {
                    dispatch(getAddressByZipCode())
                }
            }, 600);
            return () => {
                clearTimeout(timeout);
            }
        }, [person.address.house_number, person.address.zip_code]
    );

    const handleForm = () => {
        const currentDamageDate = moment(damageDate).format('YYYY-MM-DD');

        if (
            moment(currentDamageDate).isBefore(moment().subtract(1, 'year').subtract(1, 'day')) ||
            moment(currentDamageDate).isAfter(moment())
        ) {
            if (moment(currentDamageDate).isBefore(moment().subtract(1, 'year').subtract(1, 'day'))) {
                dispatch(setDateErrorText('Wij accepteren geen aanvragen van meer dan 1 jaar geleden.'));
                return
            }

            dispatch(setDateErrorText('De datum kan niet in de toekomst liggen.'));
            dispatch(setDateError(true));
            return
        }

        dispatch(setDateError(false));
        setErrorForm(false)

        if (!person.first_name.trim() || !person.last_name.trim() || !person.email.includes('@') || !person.address.zip_code || !person.address.house_number || !person.address.street || !person.address.city || !person.mobile_number.trim() || !greenCardCode.trim() || !currentDamageDate) {
            setErrorForm(true)
            return
        }

        dispatch(enableMobileNumberCheckModal())
    }

    if (dossier) {
        return (

            <form className="s-appointment__group border-color-primary" id="naw-form" ref={scrollRef} onSubmit={(e) => {
                e.preventDefault();
                handleForm()
            }}>
                <h1 className="s-appointment__group__title">Vul je gegevens in</h1>

                <div className="c-form__row c-form__row--name">

                    <FormInput required={true}
                               name={'first_name'}
                               value={person.first_name}
                               type={'text'}
                               label={'Voornaam'}
                               error={errorForm && !person.first_name.trim() ? 'Voer een valide voornaam in' : false}
                               onChange={(value) => {
                                   dispatch(setGeneralStep(GeneralStepsEnum.Form))
                                   dispatch(setFirstName(value.toString()))
                               }}/>

                    <FormInput value={person.insertion}
                               name={'insertion'}
                               type={'text'}
                               label={'Tussenvoegsel'}
                               onChange={(value) => {
                                   dispatch(setGeneralStep(GeneralStepsEnum.Form))
                                   dispatch(setInsertion(value.toString()))
                               }}/>

                    <FormInput required={true}
                               name={'last_name'}
                               value={person.last_name}
                               type={'text'}
                               label={'Achternaam'}
                               error={errorForm && !person.last_name.trim() ? 'Voer een valide achternaam in' : false}
                               onChange={(value) => {
                                   dispatch(setGeneralStep(GeneralStepsEnum.Form))
                                   dispatch(setLastName(value.toString()))
                               }}/>

                </div>

                <div className="c-form__row">
                    <FormInput type={'email'}
                               name={'email'}
                               value={person.email}
                               required={true}
                               label={'E-mailadres'}
                               error={errorForm && !person.email.includes('@') ? 'Voer een valide e-mailadres in' : false}
                               onChange={(value) => {
                                   dispatch(setGeneralStep(GeneralStepsEnum.Form))
                                   dispatch(setEmail(value.toString()))
                               }}/>
                </div>

                <div className="c-form__row">
                    <FormInput required={true}
                               name={'zip_code'}
                               value={person.address.zip_code}
                               type={'text'}
                               label={'Postcode'}
                               onChange={(value) => {
                                   dispatch(setGeneralStep(GeneralStepsEnum.Form))
                                   dispatch(setAddressZipcode(value.toString()))
                               }}
                               error={errorForm && !validZipcode ? 'Voer een valide postcode in' : false}
                    />
                </div>

                <div className="c-form__row--half">
                    <FormInput required={true}
                               name={'house_number'}
                               value={person.address.house_number}
                               type={'number'}
                               label={'Huisnr.'}
                               error={errorForm && !person.address?.house_number ? 'Voer een valide huisnummer in' : false}
                               onChange={(value) => {
                                   dispatch(setGeneralStep(GeneralStepsEnum.Form))
                                   dispatch(setAddressHouseNumber(Number(value)))
                               }}/>

                    <FormInput name={'house_number_addition'}
                               value={person.address.house_number_addition}
                               type={'text'}
                               label={'Toevoeging'}
                               onChange={(value) => {
                                   dispatch(setGeneralStep(GeneralStepsEnum.Form))
                                   dispatch(setAddressHouseNumberAddition(value.toString()))
                               }}/>
                </div>

                <div className="c-form__row">
                    <FormInput required={true}
                               name={'street'}
                               value={person.address.street}
                               loading={zipcodeLoading}
                               type={'text'}
                               label={'Straatnaam'}
                               error={errorForm && !person.address?.street?.trim() ? 'Voer een valide straatnaam in' : false}
                               onChange={(value) => {
                                   dispatch(setGeneralStep(GeneralStepsEnum.Form))
                                   dispatch(setAddressStreet(value.toString()))
                               }}/>
                </div>

                <div className="c-form__row">
                    <FormInput required={true} type={'text'}
                               name={'city'}
                               value={person.address.city}
                               loading={zipcodeLoading}
                               label={'Plaats'}
                               error={errorForm && !person.address?.city?.trim() ? 'Voer een valide plaatsnaam in' : false}
                               onChange={(value) => {
                                   dispatch(setGeneralStep(GeneralStepsEnum.Form))
                                   dispatch(setAddressCity(value.toString()))
                               }}/>
                </div>

                <div className="c-form__row">
                    <FormInputPhoneNumber type={'text'}
                                          name={'phone'}
                                          value={person.mobile_number}
                                          required={true}
                                          error={errorForm && !person.mobile_number.trim() ? 'Voer een valide telefoonnummer in' : false}
                                          label={'Mobiel nummer.'}
                                          message={"Voer een valide telefoonnummer in"}
                                          onChange={(value) => {
                                              dispatch(setGeneralStep(GeneralStepsEnum.Form))
                                              dispatch(setMobile(value.toString()))
                                          }}/>
                </div>


                <div className="c-form__row">
                    <FormInput
                        type={'text'}
                        modal={'greenCard'}
                        value={greenCardCode} placeholder={'NL 111 2222 333344445555'}
                        label={'Groene kaartnummer'}
                        required={true}
                        error={errorForm && !greenCardCode.trim() ? 'Voer een valide groene kaartnummer in' : false}
                        onChange={(value) => {
                            dispatch(setGeneralStep(GeneralStepsEnum.Form))
                            dispatch(setGreenCardCode(value.toString()))
                        }}/>
                </div>

                <div className="c-form__row">
                    <FormInput
                        type={'date'}
                        onChange={(value) => dispatch(setDamageDate(value.toString()))}
                        value={damageDate}
                        label={'Datum van schade'}
                        required={true}
                        maxDate={moment().format('YYYY-MM-DD')}
                        minDate={moment().subtract(1, 'year').format('YYYY-MM-DD')}/>
                </div>

                <div className="c-form__buttons">
                    <button
                        className="c-form__buttons__single c-form__buttons__single___primary button-background-color-dark">
                        <span className="font-face-primary">Bekijk samenvatting</span>
                    </button>
                </div>
                <p className="small">
                    Meer informatie over hoe wij verantwoord met je gegevens omgaan kun je via <a
                    href="https://www.absautoherstel.nl/over-abs/privacy-en-cookieverklaring/" rel="noreferrer"
                    target="_blank">deze link</a> vinden.
                </p>

            </form>

        )
    } else return null

}

export default Form
